import React, {
  FC,
  HTMLAttributes,
  ReactHTMLElement,
  useEffect,
  useMemo,
} from "react";
import Countdown from "react-countdown";
import { useBattleContext } from "../../context";
import { PlayerInfo } from "../../types";
import { DEFAULT_AVATAR } from "../../../constants";
import displayUserName from "../../../utils/display-username";

type Props = {};

interface PlayerInfoProps extends HTMLAttributes<HTMLDivElement>, PlayerInfo {}

const TopBar = (props: Props) => {
  const { battleState, gameInfo } = useBattleContext();

  const time = useMemo(() => {
    if (!gameInfo?.gameRules?.matchTimeout)
      return new Date(Date.now() + 500000);
    return new Date(Date.now() + gameInfo?.gameRules?.matchTimeout);
  }, [gameInfo?.gameRules]);

  

  const players = {
    teamA: battleState?.players?.[0],
    teamB: battleState?.players?.[1],
  };

  const PlayerInfo: FC<PlayerInfoProps> = ({ avatar, username, ...rest }) => (
    <div className="d-flex flex-column gap-1 align-items-center">
      <div className="top-bar-player" {...rest}>
        <img src={avatar || DEFAULT_AVATAR} className="w-100 rounded-circle" />
      </div>
      <p
        title={displayUserName(username, 100)}
        className=" fs-small lh-1 text-white text-shadow"
      >
        {displayUserName(username, 7)}
      </p>
    </div>
  );

  return (
    <div className="top-bar">
      <div className="mx-auto d-flex gap-3 align-items-start">
        {players.teamA?.map((player) => (
          <PlayerInfo {...player} />
        ))}
        <div className="p-relative">
          <Countdown
            date={time}
            renderer={({ formatted }) => (
              <div className="timer top-5 w-100 text-center">
                {formatted.minutes}:{formatted.seconds}
              </div>
            )}
          />
          <img width={75} src="/assets/images/arena/board.png" />
        </div>
        {players.teamB?.map((player) => (
          <PlayerInfo {...player} />
        ))}
      </div>
    </div>
  );
};

export default TopBar;
