import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useBattleContext } from "../../context";
import useGameLogic from "../../hooks/useGameLogic";
import CircularProgressBar from "../circular-progress-bar";
import CircleProgressBar from "../circle-progress-bar";
import Countdown from "react-countdown";
type Props = {};

const TroopSlots = (props: Props) => {
  const { battleState, coolDowns } = useBattleContext();
  const { selectUnit } = useGameLogic();
  const { units, selectedTroop, addCooldown } = battleState;
  const myUnits = useMemo(
    () => units.filter((unit) => unit.isFriend && unit.x < 0),
    [units]
  );
  const [timeLeft, setTimeLeft] = useState<number>(addCooldown);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    setTimeLeft(addCooldown);
  }, [addCooldown]);

  const troopLength = myUnits.length;
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  React.useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const isEnableToAdd = useMemo(() => {
    return currentTime > coolDowns.troopCoolDownDate;
  }, [currentTime, coolDowns.troopCoolDownDate]);

  return (
    <div className="game-bar-troop-slots gap-2 row w-100">
      {myUnits.map((troop, index) => (
        <div
          onClick={() => selectUnit(troop.playerTroopId)}
          className={classNames(
            "game-bar-troop-slot p-0 col-3 position-relative",
            {
              disabled: !isEnableToAdd,
              selected:
                selectedTroop &&
                selectedTroop?.playerTroopId === troop.playerTroopId,
            }
          )}
          key={troop.playerTroopId}
        >
          {/* <CircleProgressBar
            key={troop.playerTroopId+"-"+coolDowns.troopCoolDownDate}
              showText={false}
              endDate={new Date(coolDowns.troopCoolDownDate)}
              width={60}
              height={60}
              thickness={2}
              accentColor="#48a7af"
              backgroundColor="rgba(0,0,0,0.3)"
              className="position-absolute top-0 left-0 z-index-1"
            /> */}

          <div className="level">{troop.level}</div>
          <div className="content">
            <img
              className="circle"
              width={"100%"}
              src={troop.troop.icon}
              alt={troop.name}
            />
          </div>
          {!isEnableToAdd && (
            <Countdown
              date={new Date(coolDowns.troopCoolDownDate)}
              renderer={({ seconds, completed }) => {
                return (
                  <div className="position-absolute top-0 right-0 text-white mx-auto left-0 z-index-1 mt-3">
                    {seconds}
                  </div>
                );
              }}
              />
            )}
        </div>
      ))}
      {troopLength < 10 &&
        new Array(10 - troopLength).fill(0).map((_, index) => (
          <div className="game-bar-troop-slot position-relative disabled ">
            <div className="content"></div>
          </div>
        ))}

    </div>
  );
};

export default TroopSlots;
