// useEventHandler.ts
import React, { useCallback, useEffect, useMemo } from 'react';
import { ArenaBattleStateEnum, BattleUpdateType, ConnectionStatus, MessagesVariant, SignalREvent, TroopActions, TroopAttribute, ViewState } from '../types/enum';
import { BattleResult, BattleRules, EventHandlers, GameInfo, PlayerInfo, SuggestedMatchData, TroopAction, TroopActionTarget, Unit } from '../types';
import useGameLogic from './useGameLogic';
import { useBattleContext } from '../context';
import prepareTroops from '../utils/prepare-troops';
import { useSelector } from 'react-redux';
import usePing from './usePing';
import { useLocation, useNavigate } from 'react-router-dom';
import useTriggerEvent from './useTriggerEvent';
import { EventNames } from '../utils/event-bus';
import useToast from '../../hooks/useToast';
import useSounds from '../../hooks/useSounds';


const useEventHandler = () => {
    const triggerEvent = useTriggerEvent()
    const { timer ,pong } = usePing();
    const { } = useGameLogic()
    const navigate = useNavigate()
    const location = useLocation()
    const toast = useToast();
    const userInfo = useSelector((state: any) => state.userInfo);
    const { messages, setMessages,
        setSuggestedMatch,
        setCoolDowns, connectionStatus, setConnectionStatus,
        battleState, setBattleState, setGameInfo, isFindingMatch, setIsFindingMatch } = useBattleContext();
    const battleStateRef = React.useRef(battleState);
    const pathnameRef = React.useRef(location.pathname)
    const { playSound } = useSounds();

    useEffect(() => {
        if (pathnameRef.current !== location.pathname) {
            pathnameRef.current = location.pathname
        }
    }, [location.pathname])

    useEffect(() => {
        battleStateRef.current = battleState;
    }, [battleState]);

    const fetchTroops = async () => {
        try {
            const response = await fetch(
                "https://battle.chainoflegends.com/api/battle/troops"
            );
            // const response = await fetch('https://localhost:7291/api/battle/troops');
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            var storedArmy = JSON.parse(localStorage.getItem("selectedArmy") || "[]");
            setBattleState((prevState) => ({
                ...prevState,
                selectedArmy: storedArmy,
                troops: data,
            })); // Update state with the fetched data
        } catch (error) {
            console.error("Error fetching troops:", error);
        }
    };

    useEffect(() => {
        fetchTroops();
    }, []);

    const handlePlayerState = (playerState: ArenaBattleStateEnum) => {
        if (!playerState) return
        switch (playerState) {
            case ArenaBattleStateEnum.Free:
                {
                }
                break;
            case ArenaBattleStateEnum.FindingMatch:
                break;
            case ArenaBattleStateEnum.HostingMatch:
                {
                    const target = '/arena/host-match'
                    // TODO: Fix this
                    // if (pathnameRef.current !== target) navigate(target)
                }
                break;
            case ArenaBattleStateEnum.ReadyCheck:
                break;
            case ArenaBattleStateEnum.ReadyCheckSubmitted:
                break;
            case ArenaBattleStateEnum.Formation:
                {
                    const target = '/arena/formation'
                    if (pathnameRef.current !== target) navigate(target)
                }
                break;
            case ArenaBattleStateEnum.FormationSubmitted:
                {
                    const target = '/arena/formation'
                    if (pathnameRef.current !== target) navigate(target)
                }
                break;
            case ArenaBattleStateEnum.InBattle:
                {
                    const target = '/arena/live-battle'
                    if (pathnameRef.current !== target) navigate(target)
                }
                break;
            default:
                break;
        }
    }
    const onPong = (pingValue: number) => {
        triggerEvent(EventNames.Ping, pingValue)
        pong()
        if (connectionStatus !== ConnectionStatus.Connected) setConnectionStatus(ConnectionStatus.Connected)
        
    };

    const messageHandler = useCallback((message: string, type: number) => {
        // TODO: Add message handler
        const variant = type === 3 ? MessagesVariant.Error : MessagesVariant.Success;
        setMessages((prevState) => ([
            ...prevState,
            {
                message,
                variant,
            }
        ]));
    }, []);

    const updateUnitPosition = (unit: Unit, action: TroopAction) => {
        setCoolDowns((prevState) => ({
            ...prevState,
            troops: {
                ...prevState.troops,
                [unit.playerTroopId]: {
                    ...prevState.troops[unit.playerTroopId],
                    moveCoolDownDate: Date.now() + battleStateRef.current.battleRules?.moveCoolDown!,
                },
            },
        }))
        return ({
            ...unit,
            position: { x: action.targetX, y: action.targetY },
            x: action.targetX, y: action.targetY,
            moveKey: action.actionType <= 2 ? (unit.moveKey || 0) + 1 : unit.moveKey,
            attackKey: action.actionType === 3 ? (unit.attackKey || 0) + 1 : unit.attackKey,
            healKey: action.actionType === 4 ? (unit.healKey || 0) + 1 : unit.healKey,
        })
    };

    const updateUnitAttributes = (unit: Unit, targets: TroopActionTarget[], playerTroopId: number) => {
        let updatedUnit = { ...unit };
        targets.forEach((target) => {
            switch (target.targetAttribute) {
                case TroopAttribute.Health: {// Health

                    const hpChange = target.newValue - updatedUnit.health;
                    if (hpChange > 0) {
                        // TODO: add healing config 
                        setCoolDowns((prevState) => ({
                            ...prevState,
                            troops: {
                                ...prevState.troops,
                                [playerTroopId]: {
                                    ...prevState.troops[playerTroopId],
                                    healingCoolDownDate: Date.now() + battleStateRef.current.battleRules?.attackCooldown!,
                                },
                            },
                        }))
                    }

                    if (hpChange < 0) {
                        setCoolDowns((prevState) => ({
                            ...prevState,
                            troops: {
                                ...prevState.troops,
                                [playerTroopId]: {
                                    ...prevState.troops[playerTroopId],
                                    actionCoolDownDate: Date.now() + battleStateRef.current.battleRules?.attackCooldown!,
                                },
                            },
                        }))
                    }
                    if (hpChange !== 0) {
                        triggerEvent(EventNames.StartAttack, {
                            modelId: playerTroopId + "",
                        })
                        triggerEvent(EventNames.Damage, {
                            modelId: target.id + "",
                            damage: hpChange
                        })

                        updatedUnit = {
                            ...updatedUnit,
                            healthChanges: [
                                ...(updatedUnit.healthChanges || []).filter(
                                    (h) => Date.now() - h.id < 1500
                                ),
                                { id: Date.now(), value: hpChange },
                            ],
                            health: target.newValue,
                        };
                    }
                    break;
                }
                case TroopAttribute.Armor: // Armor
                    // console.log(
                    //     `${updatedUnit.troop.name} level ${updatedUnit.level} armor updated from ${updatedUnit.armor} to ${target.newValue}`
                    // );
                    updatedUnit.armor = target.newValue;
                    break;
                case TroopAttribute.MagicResistance: // Magic Resistance
                    // console.log(
                    //     `${updatedUnit.troop.name} level ${updatedUnit.level} magic resist updated from ${updatedUnit.magicResistance} to ${target.newValue}`
                    // );
                    updatedUnit.magicResistance = target.newValue;
                    break;
                case TroopAttribute.Damage: // Damage
                    updatedUnit.damage = target.newValue;
                    break;
                case TroopAttribute.Range: // Range
                    updatedUnit.range = target.newValue;
                    break;
                case TroopAttribute.Buffs: // Buffs
                    updatedUnit.buffs = target.newValue;
                    break;
                default:
                    break;
            }
        });
        return updatedUnit;
    };

    const updateUnits = (units: Unit[], action: TroopAction, updateType: number) => {
        if (updateType !== 5) return units;
        // console.log("updateUnits", units, action, updateType);
        return units.map((unit) =>
            unit.playerTroopId === action.playerTroopId
                ? updateUnitPosition(unit, action)
                : unit
        );
    };

    const applyActionTargets = (units: Unit[], action: TroopAction) => {
        if (!action) return units;
        return units.map((unit) => {
            const targets = action.targets?.filter(
                (t) => t && t.id === unit.playerTroopId
            );
            return targets && targets.length > 0
                ? updateUnitAttributes(unit, targets, action.playerTroopId)
                : unit;
        });
    };


    const eventUpdateHandler = useCallback((message: string, updateType: BattleUpdateType, action: TroopAction) => {
        // console.log("new battle update: ", message, updateType, action);
        setBattleState((prevState) => {
            const actionedUnit = prevState.units.find(
                (u) => u.playerTroopId === action?.playerTroopId
            );
            let newUnits = updateUnits(prevState.units, action, updateType);
            newUnits = applyActionTargets(newUnits, action);
            const resetAddTroopCooldown =
                actionedUnit &&
                action &&
                actionedUnit.playerId === userInfo.id &&
                action.actionType === TroopActions.Enter;
            if (resetAddTroopCooldown) setCoolDowns((prevState) => ({
                ...prevState,
                troopCoolDownDate: Date.now() + battleStateRef.current.battleRules?.troopAddCooldown!,
            }))

            return {
                ...prevState,
                addCooldown: prevState.addCooldown + (resetAddTroopCooldown ? 1 : 0),
                units: newUnits,
            };
        });
    }, []);

    const onStartBattle = (_battleId: string, defenders: any, attackers: any, hostPlayer: PlayerInfo[], opponentPlayer: PlayerInfo[], battleRules: BattleRules) => {
        if (battleStateRef.current.troops.length == 0) {
            setTimeout(() => {
                onStartBattle(_battleId, attackers, defenders, hostPlayer, opponentPlayer, battleRules);
            }, 1000);
            return;
        }
        attackers = attackers || [];
        defenders = defenders || [];
        const playerIsAttacker =
            attackers.find((a) => a.playerId === userInfo.id) != null;
        attackers = prepareTroops(attackers, playerIsAttacker, true, battleStateRef);
        defenders = prepareTroops(defenders, playerIsAttacker, false, battleStateRef);
        setBattleState((prevState) => ({
            ...prevState,
            battleRules,
            players: [hostPlayer, opponentPlayer],
            viewState: ViewState.LiveBattle,
            playerIsAttacker: playerIsAttacker,
            units: attackers.concat(defenders),
            battleId: _battleId,
        }));
        navigate('/arena/live-battle')

        // setViewState(ViewState.LiveBattle);
    };

    const clearSteps = () => {
        setIsFindingMatch(false)
        setSuggestedMatch(undefined)
    }

    const onFormationCancel = () => {
        setTimeout(() => {
            navigate('/arena')
            toast({
                ok: false,
                message: "Formation Canceled"
            })
        }, 2000);
    }

    const eventHandlers: EventHandlers = useMemo(() => ({
        onMessageReceived: (username: string, message: string) => {
            // console.log('Message received:', username, message);
        },
        onBattleUpdate: (message: string, updateType: BattleUpdateType, action) => {
            // console.log('Battle update:', message, updateType, action);
            if (updateType === BattleUpdateType.FindMatchCanceled) {
                clearSteps()
            }
            if (updateType === BattleUpdateType.ReadyCheckCanceled) {
                toast({
                    ok: false,
                    message: "Ready Check Canceled"
                })
                navigate("/arena")
            }
            if (updateType === BattleUpdateType.AFKTimeout || updateType === BattleUpdateType.BattleTimeout || updateType === BattleUpdateType.EndBattle) {
                triggerEvent(EventNames.BattleEnd, null);
            }
            if (updateType === BattleUpdateType.FormationCanceled) onFormationCancel();
            if (message) return messageHandler(message, updateType);
            eventUpdateHandler(message, updateType, action);
        },
        onReadyCheck: (data: SuggestedMatchData) => {
            // console.log("onReadyCheck", data)
            setIsFindingMatch(false);
            setSuggestedMatch(data)
            navigate('/arena/battle-lobby')
            playSound('notice')
            // TODO : Get Battle Data and Store it
        },
        onStartBattle: (battleId: string, attackers: any, defenders: any, hostPlayer: PlayerInfo[], opponentPlayer: PlayerInfo[], battleRules: BattleRules) => {
            // console.log('Battle Started:', battleId, attackers, defenders);
            onStartBattle(battleId, attackers, defenders, hostPlayer, opponentPlayer, battleRules);
            clearSteps();
        },
        onPong,
        onBattleList: (liveBattles: any[]) => {
            // console.log('Live Battles:', liveBattles);
        },
        onNewBattle: (battleId: string, attackerNames: string[], defenderNames: string[]) => {
            // console.log('New Battle:', battleId);
        },
        onEndBattle: (battleId: string, winnerPlayerIds: number[], battleResult: BattleResult) => {
            triggerEvent(EventNames.BattleResult, battleResult)
        },
        onStatusChange: (status: string, error?: any) => {
            // console.log('Status Change:', status, error);
        },
        onSignIn: (gameInfo: GameInfo) => {
            // console.log('Sign In: ', gameInfo);
            handlePlayerState(gameInfo.playerInfo?.arenaPlayerState)
            setGameInfo(gameInfo);
        },
        onStartFormation: (battleId, army, battleRules, battleModeInfo) => {
            // console.log('Start Formation for Battle:', battleId);
            // setViewState(ViewState.TroopSelection);
            setBattleState(prev => ({
                ...prev,
                battleRules
            }))
            navigate('/arena/formation')
            setTimeout(() => {
                triggerEvent(EventNames.StartFormation, { troops: army, rules: battleRules, battleModeInfo: battleModeInfo })
            }, 1000);
        },
        onHostCreated: (battleId: string) => {
            // console.log('Host Created:', battleId);
            navigate('/arena/host-match?id=' + battleId)
        },
    }), []);



    return eventHandlers;
};

export default useEventHandler;
