import React from "react";
import MidBox from "../../../../components/Boxes/MidBox";
import WatchBoxEmptyState from "./empty-state";
import classNames from "classnames";
import { Link } from "react-router-dom";

type Props = {};

const WatchBox = (props: Props) => {
  return (
    <MidBox
      noTitle
      noMargin
      title="Watch"
      className="w-100"
      containerClass="px-lg-4 "
      style={{ minHeight: 195 }}
    >
      <img
        src="/assets/images/arena/icons/watch.png"
        width={86}
        className="box-logo"
        alt="watch-bg"
      />
      <div style={{ marginTop: -20 }} className="mx-3">
        <div
          style={{ minHeight: 200 }}
          className={classNames(
            "camp-box-content position-relative  bg-2 rounded-3  d-flex flex-column  p-3",
            {
              "justify-content-center align-items-center": true,
              // !isLoading && playerTroops.length === 0,
            }
          )}
        >
          <WatchBoxEmptyState />
        </div>
      </div>
      <Link className="w-100 text-center d-block" to="/arena/watch">
        <a className="dark-yellow mt-2 fs-small d-block font-normal ">
          See All
        </a>
      </Link>
    </MidBox>
  );
};

export default WatchBox;
