import { EventEmitter } from 'events';
import { BattleModeInfo, BattleResult, BattleRules, FormationCampArmy } from '../types';

// Define an enum for event names
export enum EventNames {
  StartAttack = 'startAttack',
  Damage = 'damage',
  BattleEnd="battleEnd",
  BattleResult="battleResult",
  Ping="ping",
  StartFormation="startFormation",
  // Add other event names as needed
}

// Define types for our events using the enum keys
export interface EventMap {
  [EventNames.StartAttack]: { modelId: string };
  [EventNames.Damage]: { modelId: string , damage: number };
  [EventNames.BattleEnd]: null;
  [EventNames.Ping]: number;
  [EventNames.StartFormation]: { troops: FormationCampArmy[], rules: BattleRules, battleModeInfo: BattleModeInfo };
  [EventNames.BattleResult]: BattleResult;
} 

class EventBus {
  private emitter = new EventEmitter();

  on<K extends keyof EventMap>(event: K, listener: (payload: EventMap[K]) => void): this {
    this.emitter.on(event, listener);
    return this;
  }

  off<K extends keyof EventMap>(event: K, listener: (payload: EventMap[K]) => void): this {
    this.emitter.off(event, listener);
    return this;
  }

  emit<K extends keyof EventMap>(event: K, payload: EventMap[K]): boolean {
    return this.emitter.emit(event, payload);
  }
}

export default new EventBus();